<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.templates.service')}}</h4>
            <div class="form-group">
              <select v-model="selectedService" class="form-control form-control-sm">
                <option v-for="item in servicesList" :key="item.id" :value="item.id">
                  {{item.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6"><h4 class="card-title">{{$t('views.templates.templates')}}</h4></div>
              <div class="col-lg-6 text-right">
                <div @click="showAddTemplatePopup" class="btn btn-sm btn-success">
                  <i class="fa fa-plus"></i>
                  {{ $t('views.templates.template') }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-12">
                <table class="table" style="cursor: pointer;">
                  <thead>
                    <tr>
                      <th>{{$t('views.templates.category_name')}}</th>
                      <th>{{$t('views.templates.name')}}</th>
                      <th>{{$t('views.templates.custom_parameters')}}</th>
                      <th>{{$t('views.templates.template_img')}}</th>
                      <th>{{$t('popularWords.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in templatesList" :key="item.id">
                      <td @click="showEditTemplatePopup(item.id)">{{ item.category_name }}</td>
                      <td @click="showEditTemplatePopup(item.id)">{{ item.name }}</td>
                      <td @click="showEditTemplatePopup(item.id)">{{ item.parameters_decode }}</td>
                      <td @click="showEditTemplatePopup(item.id)">
                        {{ item.servicesTemplatesFiles.length }} {{ $t('popularWords.pcs') }}
                      </td>
                      <td>
                        <div @click="showDeleteTemplatePopup(item.id)" class="btn btn-sm btn-danger">
                          <i class="fas fa-times"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>    
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!-- Добавление шаблона -->
    <popup
      :closeButton="addTemplatePopup.closeButton"
      :actionButton="addTemplatePopup.actionButton"
      :actionClass="addTemplatePopup.actionClass"
      :show="addTemplatePopup.show"
      @closePopup="closeAddTemplatePopup"
      @actionPopup="addTemplatePopupSubmit"
    >
      <div slot="header">{{$t('views.templates.create_template')}}</div>
      <div slot="body">
        <form class="needs-validation">
          <div class="form-group">
            <label for="newTemplate_category_name">{{ $t('views.templates.category_name') }}</label>
            <input
                id="newTemplate_category_name"
                v-model="newTemplate.category_name"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newTemplate.category_name.$error }"
            />
            <div v-if="submitted && $v.newTemplate.category_name.$error" class="invalid-feedback">
              <span v-if="!$v.newTemplate.category_name.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newTemplate.category_name.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newTemplate.category_name.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 80
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="newTemplate_name">{{ $t('views.templates.name') }}</label>
            <input
                id="newTemplate_name"
                v-model="newTemplate.name"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newTemplate.name.$error }"
            />
            <div v-if="submitted && $v.newTemplate.name.$error" class="invalid-feedback">
              <span v-if="!$v.newTemplate.name.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newTemplate.name.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newTemplate.name.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 150
              </span>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 text-center">
              {{ $t('views.templates.custom_parameters') }}
            </div>

            <div class="col-12">
              <div class="row mt-3" v-for="(item, index) in newTemplateParameters" :key="index">
                <div class="col-5">
                  <div class="form-group">
                    <label>{{ $t('popularWords.key') }}</label>
                    <input
                        v-model="item.key"
                        type="text"
                        class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>{{ $t('popularWords.type') }}</label>
                    <select v-model="item.type" class="form-control form-control-sm">
                      <option value="string">{{ $t('popularWords.string') }}</option>
                      <option value="int">{{ $t('popularWords.integer') }}</option>
                      <option value="float">{{ $t('popularWords.float') }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>{{ $t('popularWords.value') }}</label>
                    <input
                        v-model="item.value"
                        type="text"
                        class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-2 text-center">
              <div @click="addParam" class="btn btn-sm btn-outline-info">{{ $t('views.templates.add_param') }}</div>
            </div>
          </div>

          <div class="col-12 text-center mt-4">
            {{ $t('views.templates.template_imgs') }}
          </div>

          <div v-for="(item, index) in newTemplateFiles" :key="index" class="row mt-2">
            <div class="col-12 text-center">-</div>
            <div class="col-12">
              <div class="form-group">
                <label for="newTemplateFiles_codename">{{ $t('views.templates.image_codename') }}</label>
                <input
                    id="newTemplateFiles_codename"
                    v-model="item.template_codename"
                    type="text"
                    class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="col-12">
              <label>{{$t('views.templates.template_img')}}</label>
              <div class="custom-file">
                <input
                    @change="selectFiles(index, $event)"
                    type="file"
                    class="custom-file-input"
                />
                <label class="custom-file-label" for="img">{{$t('popularWords.chooseFile')}}</label>
              </div>
            </div>
            <div class="text-center col-12">
              <small v-if="item.file[0]">{{ item.file[0].name }}</small>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 text-center">
              <div @click="pushTemplateFile" class="btn btn-sm btn-outline-info">
                {{ $t('views.templates.add_img') }}
              </div>
            </div>
          </div>

          

        </form>
      </div>
    </popup>


    <!-- Изменение шаблона -->
    <popup
      :closeButton="editTemplatePopup.closeButton"
      :actionButton="editTemplatePopup.actionButton"
      :actionClass="editTemplatePopup.actionClass"
      :show="editTemplatePopup.show"
      @closePopup="closeEditTemplatePopup"
      @actionPopup="editTemplatePopupSubmit"
    >
      <div slot="header">{{$t('views.templates.create_template')}}</div>
      <div slot="body">
        <form class="needs-validation">
          <div class="form-group">
            <label for="newTemplate_category_name">{{ $t('views.templates.category_name') }}</label>
            <input
                id="newTemplate_category_name"
                v-model="newTemplate.category_name"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newTemplate.category_name.$error }"
            />
            <div v-if="submitted && $v.newTemplate.category_name.$error" class="invalid-feedback">
              <span v-if="!$v.newTemplate.category_name.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newTemplate.category_name.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newTemplate.category_name.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 80
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="newTemplate_name">{{ $t('views.templates.name') }}</label>
            <input
                id="newTemplate_name"
                v-model="newTemplate.name"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newTemplate.name.$error }"
            />
            <div v-if="submitted && $v.newTemplate.name.$error" class="invalid-feedback">
              <span v-if="!$v.newTemplate.name.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newTemplate.name.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newTemplate.name.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 150
              </span>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 text-center">
              {{ $t('views.templates.custom_parameters') }}
            </div>

            <div class="col-12">
              <div class="row mt-3" v-for="(item, key, index) in newTemplateParameters" :key="index">
                <div class="col-5">
                  <div class="form-group">
                    <label>{{ $t('popularWords.key') }}</label>
                    <input
                        v-model="item.key"
                        type="text"
                        class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>{{ $t('popularWords.type') }}</label>
                    <select v-model="item.type" class="form-control form-control-sm">
                      <option value="string">{{ $t('popularWords.string') }}</option>
                      <option value="int">{{ $t('popularWords.integer') }}</option>
                      <option value="float">{{ $t('popularWords.float') }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>{{ $t('popularWords.value') }}</label>
                    <input
                        v-model="item.value"
                        type="text"
                        class="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div class="col-12 text-center">
                  <div @click="removeEditTemplateParameter(index)" class="btn btn-sm btn-danger">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-2 text-center">
              <div @click="addParam" class="btn btn-sm btn-outline-info">{{ $t('views.templates.add_param') }}</div>
            </div>
          </div>

          <div class="col-12 text-center mt-4">
            {{ $t('views.templates.template_imgs') }}
          </div>

          <div class="col-12 mt-2">
            <div 
              v-for="(item, index) in existsTemplateFiles" 
              :key="item.id"
              class="row mt-3"
            >
              <div class="col-4">{{ item.template_codename }}</div>
              <div class="col-4">
                <img :src="item.img_url" width="80">
              </div>
              <div class="col-4">
                <div @click="removeExistsImg(index)" class="btn btn-sm btn-danger">
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(item, index) in newTemplateFiles" :key="index" class="row mt-2">
            <div class="col-12 text-center">-</div>
            <div class="col-12">
              <div class="form-group">
                <label for="newTemplateFiles_codename">{{ $t('views.templates.image_codename') }}</label>
                <input
                    id="newTemplateFiles_codename"
                    v-model="item.template_codename"
                    type="text"
                    class="form-control form-control-sm"
                />
              </div>
            </div>
            <div class="col-12">
              <label>{{$t('views.templates.template_img')}}</label>
              <div class="custom-file">
                <input
                    @change="selectFiles(index, $event)"
                    type="file"
                    class="custom-file-input"
                />
                <label class="custom-file-label" for="img">{{$t('popularWords.chooseFile')}}</label>
              </div>
            </div>
            <div class="text-center col-12">
              <small v-if="item.file[0]">{{ item.file[0].name }}</small>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 text-center">
              <div @click="pushTemplateFile" class="btn btn-sm btn-outline-info">
                {{ $t('views.templates.add_img') }}
              </div>
            </div>
          </div>

          

        </form>
      </div>
    </popup>


    <popup
      :closeButton="deleteTemplatePopup.closeButton"
      :actionButton="deleteTemplatePopup.actionButton"
      :actionClass="deleteTemplatePopup.actionClass"
      :show="deleteTemplatePopup.show"
      @closePopup="closeDeleteTemplatePopup"
      @actionPopup="deleteTemplatePopupSubmit"
    >
      <div slot="header">{{$t('views.templates.delete_template')}}</div>
      <div slot="body">
        {{$t('views.templates.delete_template_text')}}
      </div>
    </popup>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
//import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";
import Popup from "../../../components/Popup.vue";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

//const copyArrObj = new CopyArrayAndObject();

/**
 * Printers component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Popup,
},
  data() {
    return {
      submitted: false,

      selectedService: 0,
      servicesList: [],
      templatesList: [],

      // Новый шаблон
      addTemplatePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editTemplatePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      newTemplate: {
        id: 0,
        category_name: '',
        name: '',
      },
      newTemplateFiles: [],
      newTemplateParameters: [],
      existsTemplateFiles: [],

      deleteTemplatePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletedTemplateId: 0,

      title: this.$t('menuitems.services.list.templates'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.services.list.templates'),
          active: true
        }
      ],
    };
  },
  validations: {
    newTemplate: {
      category_name: { required, minLength: minLength(1), maxLength: maxLength(80) },
      name: { required, minLength: minLength(1), maxLength: maxLength(150) }
    }
  },
  watch: {
    selectedService: function(){
      this.getTemplates();
    }
  },
  computed: {
    
  },
  methods: {
    closeDeleteTemplatePopup(){
      this.deleteTemplatePopup.show = false;
    },
    showDeleteTemplatePopup(id){
      this.deleteTemplatePopup.show = true;
      this.deletedTemplateId = id;
    },
    deleteTemplatePopupSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedTemplateId);
      axios
          .post(`/v1/services-list/delete-template`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.submitted = false;
              this.closeDeleteTemplatePopup();
              this.getTemplates();
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    removeEditTemplateParameter(idx){
      this.newTemplateParameters.splice(idx, 1);
    },
    removeExistsImg(idx){
      this.existsTemplateFiles.splice(idx, 1);
    },
    showEditTemplatePopup(templateId){
      this.editTemplatePopup.show = true;
      for(let key in this.templatesList){
        if(+this.templatesList[key].id === +templateId){
          this.$set(this.newTemplate, 'id', this.templatesList[key].id);
          this.$set(this.newTemplate, 'category_name', this.templatesList[key].category_name);
          this.$set(this.newTemplate, 'name', this.templatesList[key].name);

          if(this.templatesList[key].parameters_decode.length){
            this.newTemplateParameters = this.templatesList[key].parameters_decode;
          }
          if(this.templatesList[key].servicesTemplatesFiles.length){
            this.existsTemplateFiles = this.templatesList[key].servicesTemplatesFiles;
          }
        }
      }
    },
    closeEditTemplatePopup(){
      this.editTemplatePopup.show = false;
      this.resetData();
    },
    editTemplatePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newTemplate.$touch();
      if(!this.$v.newTemplate.$invalid){
        let formData = new FormData();

        formData.append('service_id', this.selectedService);

        for(let key in this.newTemplate){
          formData.append(key, this.newTemplate[key]);
        }
        if(this.newTemplateFiles.length){
          let z = 0;
          for(let key in this.newTemplateFiles){
            formData.append(
              'files[' +z +']['+ this.newTemplateFiles[key].template_codename +']',
              this.newTemplateFiles[key].file[0]
            );
            formData.append(
              'filesList[' +z +']',
              this.newTemplateFiles[key].template_codename
            );
            z++;
          }
        }
        if(this.existsTemplateFiles.length){
          let z = 0;
          for(let key in this.existsTemplateFiles){
            formData.append(
              'existsFiles[' +z +'][id]',
              this.existsTemplateFiles[key].id
            );
            z++;
          }
        }
        if(this.newTemplateParameters.length){
          for(let key in this.newTemplateParameters){
            formData.append(
              'parameters[' +key +'][key]', 
              this.newTemplateParameters[key].key
            );
            formData.append(
              'parameters[' +key +'][type]', 
              this.newTemplateParameters[key].type
            );
            formData.append(
              'parameters[' +key +'][value]', 
              this.newTemplateParameters[key].value
            );
          }
        }
        axios
          .post(`/v1/services-list/update-template`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.submitted = false;
              this.closeEditTemplatePopup();
              this.getTemplates();
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
      }
    },
    addParam(){
      let row = {key: '', type: '', value: ''};
      // this.newTemplateParameters.push(row);
      this.$set(this.newTemplateParameters, this.newTemplateParameters.length, row);
    },
    selectFiles(idx, event) {
      this.newTemplateFiles[idx].file = event.target.files;
      //this.banner.file = event.target.files;
    },
    pushTemplateFile(){
      let row = {template_codename: '', file: []};
      this.$set(this.newTemplateFiles, this.newTemplateFiles.length, row);
    },
    addTemplatePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newTemplate.$touch();
      if(!this.$v.newTemplate.$invalid){
        let formData = new FormData();

        formData.append('service_id', this.selectedService);

        for(let key in this.newTemplate){
          formData.append(key, this.newTemplate[key]);
        }
        if(this.newTemplateFiles.length){
          let z = 0;
          for(let key in this.newTemplateFiles){
            formData.append(
              'files[' +z +']['+ this.newTemplateFiles[key].template_codename +']',
              this.newTemplateFiles[key].file[0]
            );
            formData.append(
              'filesList[' +z +']',
              this.newTemplateFiles[key].template_codename
            );
            z++;
          }
        }
        if(this.newTemplateParameters.length){
          for(let key in this.newTemplateParameters){
            formData.append(
              'parameters[' +key +'][key]', 
              this.newTemplateParameters[key].key
            );
            formData.append(
              'parameters[' +key +'][type]', 
              this.newTemplateParameters[key].type
            );
            formData.append(
              'parameters[' +key +'][value]', 
              this.newTemplateParameters[key].value
            );
          }
        }
        axios
          .post(`/v1/services-list/create-template`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.submitted = false;
              this.closeAddTemplatePopup();
              this.getTemplates();
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
      }
    },
    resetData(){
      this.newTemplate = {
        id: 0,
        category_name: '',
        name: ''
      };
      this.newTemplateFiles = [];
      this.newTemplateParameters = [];
      this.existsTemplateFiles = [];
    },
    closeAddTemplatePopup(){
      this.addTemplatePopup.show = false;
      this.resetData();
    },
    showAddTemplatePopup(){
      if(this.selectedService === 0){
        this.$store.dispatch("addNotification", {
          text: this.$t('views.templates.select_a_service_first'),
          time: 3,
          color: "danger"
        });
        return false;
      }
      this.addTemplatePopup.show = true;
    },
    getTemplates(){
      if(this.selectedService > 0){
        let formData = new FormData();
        formData.append('service_id', this.selectedService);

        axios
          .post(`/v1/services-list/get-templates`, formData)
          .then(resp => {
            console.log(resp.data);
            this.templatesList = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
      }
    },
    getActiveServices(){
      axios
          .get(`/v1/services-list/get-all`)
          .then(resp => {
            this.servicesList = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getActiveServices();
  }
};
</script>

<style scoped lang="scss">

</style>
